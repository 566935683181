<template>
  <div class="slider_area clear-fix" dir="ltr">
    <Agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
      <div
        class="slide"
        v-for="(slide, index) in slides"
        :key="index"
        :class="`slide--${index}`"
      >
        <img
          v-bind="mainImgProps"
          :src="slide"
          alt="main-slide"
          @error="showDefaultImage"
          width="200" height="200"
        />
      </div>
    </Agile>
    <Agile
      class="thumbnails"
      ref="thumbnails"
      :options="options2"
      :as-nav-for="asNavFor2"
    >
      <div
        class="slide slide--thumbniail"
        v-for="(slide, index) in slides"
        :key="index"
        :class="`slide--${index}`"
        @click="$refs.main.goTo(index)"
      >
        <img
          v-bind="mainImgProps"
          :src="slide"
          alt="main-slide"
          @error="showDefaultImage"
          width="200" height="200"
        />
      </div>
    </Agile>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        navButtons: false,
      },
      options2: {
        dots: false,
        centerMode: true,
        navButtons: false,
        slidesToShow: 3,
      },
      mainImgProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
      },
    };
  },
  beforeMount() {
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  methods: {
    showDefaultImage(ev) {
      ev.target.src = `${this.imageDomain}/assets/img/after-booking-img2.jpg`;
    },
  },
};
</script>

<style scoped>
.slider_area {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin: 0 auto;
  max-width: 900px;
}

.main {
  margin-bottom: 15px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.slide img.img-fluid {
  border-radius: 10px;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.thumbnails .agile__nav-button--prev {
  left: -45px;
}
.thumbnails .agile__nav-button--next {
  right: -45px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.thumbnails .agile__slides.agile__slides--regular {
  flex-direction: row !important;
}

.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 150px;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 55px;
  padding: 3px 3px;
  transition: opacity 0.3s;
}
.slide--thumbniail.agile__slide--active,
.slide--thumbniail:hover {
  padding: 0px;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

@media (max-width: 479px) {
  .agile__slides.agile__slides--regular {
    width: 100%;
  }
}
</style>
